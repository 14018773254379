<template>
  <a-card>
    <div class="table-operator">
      <a-form layout="inline">
        <a-row :gutter="5" style="line-height: 3.5">
          <a-col :md="6" :sm="16">
            <a-form-item label="员工姓名">
              <a-input
                v-model="queryParam.name"
                placeholder="请输入"
                @pressEnter="select"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="16">
            <a-form-item label="员工手机号">
              <a-input
                v-model="queryParam.phone"
                placeholder="请输入"
                @pressEnter="select"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="16">
            <a-form-item label="状态">
              <a-select
                style="width: 100px"
                v-model="queryParam.status"
                @change="select"
                allowClear
                placeholder="请选择"
              >
                <a-select-option :key="1">启用</a-select-option>
                <a-select-option :key="2">禁用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="16" style="text-align: right">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="select()">查询</a-button>
              <a-button
                style="margin-left: 8px"
                @click="() => ((queryParam = {}), this.select())"
                >重置</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="add">新建</a-button>
      </div>
      <a-spin :spinning="loading">
        <a-table
          :columns="columns"
          :dataSource="pageData"
          rowKey="id"
          :pagination="pagination"
          :scroll="{ x: 1500 }"
          @change="changePageSize"
        >
          <span
            slot="status"
            slot-scope="text, record"
            :class="record.status === 1 ? 'green' : 'red'"
          >
            {{ record.status === 1 ? "启用" : "禁用" }}
          </span>
          <span slot="action" slot-scope="text, record" class="blueColor">
            <a @click="edit(record)">编辑</a>&nbsp;&nbsp;
            <a @click="resetUserPassword(record)">密码重置</a>&nbsp;&nbsp;
            <a @click="updateStatus(record)">{{
              (record.status === 1 && "禁用") || "启用"
            }}</a>
          </span>
        </a-table>
      </a-spin>
    </div>
    <add-user-modal
      :visible="createVisible"
      :roles="roles"
      :facilities="facilities"
      @onCreate="() => this.onCreated()"
      @onClose="() => (this.createVisible = false)"
    />
  </a-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defaultPagination } from "../../utils/pagination";
import {
  DISABLE_USER,
  ENABLE_USER,
  GET_FACILITIES,
  GET_ROLES,
  GET_USERS,
  RESET_USER_PASSWORD
} from "../../store/modules/actions.type";
import AddUserModal from "./detail/addUserModal";
import { RoleTypes } from "../../store/modules/roles";
import axios from "axios";

export default {
  name: "users",
  components: { AddUserModal },
  data() {
    return {
      // 显示新建弹窗
      createVisible: false,

      loading: false,

      queryParam: {
        name: undefined,
        phone: undefined,
        status: undefined
      },
      // 表头
      columns: [
        {
          title: "员工姓名",
          dataIndex: "name",
          key: "name",
          fixed: "left"
        },
        {
          title: "员工账号",
          width: 200,
          dataIndex: "phone",
          key: "phone"
        },
        {
          title: "邮箱",
          dataIndex: "email",
          key: "email"
        },
        {
          title: "钉钉部门ID",
          width: 200,
          dataIndex: "dingtalkDeptId",
          key: "dingtalkDeptId"
        },
        {
          title: "钉钉ID",
          width: 200,
          dataIndex: "dingtalkUserId",
          key: "dingtalkUserId"
        },
        {
          title: "状态",
          width: 100,
          dataIndex: "status",
          key: "status",
          scopedSlots: { customRender: "status" }
        },
        {
          title: "操作",
          dataIndex: "action",
          width: "180px",
          key: "action",
          fixed: "right",
          scopedSlots: { customRender: "action" }
        }
      ],
      page: 1,
      pageSize: 20
    };
  },

  computed: {
    ...mapGetters("users", ["usersPaged", "total"]),
    ...mapGetters("roles", ["roles"]),
    ...mapGetters("facilities", ["facilities"]),

    pageData: function() {
      return this.usersPaged(this.page);
    },

    pagination: function() {
      return defaultPagination(() => {}, this.total);
    }
  },

  created: async function() {
    this.loading = true;
    const promises = [];

    promises.push(
      this.getUsers({
        page: this.page,
        rows: this.pageSize,
        ...this.queryParam
      })
    );

    promises.push(this.getRoles(RoleTypes.USER_ROLE));

    if (this.facilities.length === 0) {
      promises.push(this.getFacilities());
    }

    await Promise.all(promises);
    this.loading = false;
  },
  methods: {
    ...mapActions("users", [
      GET_USERS,
      RESET_USER_PASSWORD,
      ENABLE_USER,
      DISABLE_USER
    ]),
    ...mapActions("roles", [GET_ROLES]),
    ...mapActions("facilities", [GET_FACILITIES]),

    fetchUsers: async function() {
      this.loading = true;
      await this.getUsers({
        page: this.page,
        rows: this.pageSize,
        ...this.queryParam
      });
      this.loading = false;
    },
    changePageSize(val) {
      this.page = val.current;
      this.pageSize = val.pageSize;
      this.fetchUsers();
    },
    select() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchUsers();
    },
    updateStatus(record) {
      record.status === 2 ? this.enableUser(record) : this.disableUser(record);
    },
    add() {
      this.createVisible = true;
    },
    onCreated() {
      this.createVisible = false;
      this.select();
    },
    edit(val) {
      this.$router.push({
        path: "/authorization/users/" + val.id
      });
    }
  }
};
</script>

<style scoped></style>
