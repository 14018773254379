<template>
  <a-modal
    title="新建员工"
    :width="640"
    :visible="visible"
    :confirmLoading="loading"
    destroyOnClose
    @ok="onSubmit"
    @cancel="onCancel"
  >
    <a-form :form="form">
      <a-form-item
        label="员工姓名"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-input
          v-decorator="[
            'name',
            { rules: [{ required: true, message: '请输入' }] }
          ]"
          placeholder="请输入"
        />
      </a-form-item>
      <a-form-item label="手机号" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input
          v-decorator="[
            'phone',
            {
              rules: [
                { required: true, pattern: /^1\d{10}$/, message: '请输入' }
              ]
            }
          ]"
          placeholder="请输入"
        />
      </a-form-item>
      <a-form-item
        label="钉钉部门ID"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-input
          v-decorator="[
            'dingtalkDeptId',
            {
              rules: [
                {
                  required: false,
                  pattern: /^[0-9]*$/,
                  message: '钉钉部门ID有误差！'
                }
              ]
            }
          ]"
          placeholder="请输入"
        />
      </a-form-item>
      <a-form-item
        label="钉钉用户ID"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-input
          v-decorator="[
            'dingtalkUserId',
            {
              rules: [{ required: false, message: '请输入钉钉用户ID！' }]
            }
          ]"
          placeholder="请输入"
        />
      </a-form-item>
      <a-form-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input
          v-decorator="[
            'email',
            { rules: [{ required: true, message: '请输入' }] }
          ]"
          placeholder="请输入"
        />
      </a-form-item>
      <a-form-item
        label="所属角色"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-select
          placeholder="请选择"
          mode="multiple"
          @select="addRole"
          @deselect="removeRole"
        >
          <a-select-option
            v-for="role in roles.get(`USER_ROLE`)"
            :key="role.uuid"
          >
            {{ role.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="所属门店"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-select
          v-decorator="[
            'facilityIds',
            { rules: [{ required: false, message: '请选择所属门店' }] }
          ]"
          placeholder="请选择"
          mode="multiple"
        >
          <a-select-option v-for="item in facilities" :key="item.id">
            {{ item.optionText }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {
  ADD_ROLES_TO_USER,
  CREATE_USER
} from "../../../store/modules/actions.type";
import { mapActions } from "vuex";
import { RoleTypes } from "../../../store/modules/roles";

export default {
  name: "addUserModal",
  props: ["visible", "roles", "facilities"],

  data() {
    return {
      loading: false,
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      selectedRoles: []
    };
  },

  methods: {
    ...mapActions("users", [CREATE_USER, ADD_ROLES_TO_USER]),
    onSubmit() {
      const {
        form: { validateFields }
      } = this;

      validateFields(async (errors, values) => {
        if (!errors) {
          this.loading = true;
          const userResp = await this.createUser({ ...values });
          if (this.selectedRoles.length > 0) {
            await this.addRolesToUser({
              roles: this.roles,
              type: RoleTypes.USER_ROLE,
              roleIds: this.selectedRoles,
              userId: userResp.data.obj.id
            });
          }
          this.$emit("onCreate");
        }
        this.loading = false;
      });
    },
    onCancel() {
      this.$emit("onClose");
    },
    addRole(roleId) {
      this.selectedRoles = [...this.selectedRoles, roleId];
    },
    removeRole(toRemove) {
      this.selectedRoles = this.selectedRoles.filter(role => role !== toRemove);
    }
  }
};
</script>

<style scoped></style>
