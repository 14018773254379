var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建员工","width":640,"visible":_vm.visible,"confirmLoading":_vm.loading,"destroyOnClose":""},on:{"ok":_vm.onSubmit,"cancel":_vm.onCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"员工姓名","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          { rules: [{ required: true, message: '请输入' }] }
        ]),expression:"[\n          'name',\n          { rules: [{ required: true, message: '请输入' }] }\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"手机号","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'phone',
          {
            rules: [
              { required: true, pattern: /^1\d{10}$/, message: '请输入' }
            ]
          }
        ]),expression:"[\n          'phone',\n          {\n            rules: [\n              { required: true, pattern: /^1\\d{10}$/, message: '请输入' }\n            ]\n          }\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"钉钉部门ID","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'dingtalkDeptId',
          {
            rules: [
              {
                required: false,
                pattern: /^[0-9]*$/,
                message: '钉钉部门ID有误差！'
              }
            ]
          }
        ]),expression:"[\n          'dingtalkDeptId',\n          {\n            rules: [\n              {\n                required: false,\n                pattern: /^[0-9]*$/,\n                message: '钉钉部门ID有误差！'\n              }\n            ]\n          }\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"钉钉用户ID","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'dingtalkUserId',
          {
            rules: [{ required: false, message: '请输入钉钉用户ID！' }]
          }
        ]),expression:"[\n          'dingtalkUserId',\n          {\n            rules: [{ required: false, message: '请输入钉钉用户ID！' }]\n          }\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"邮箱","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          { rules: [{ required: true, message: '请输入' }] }
        ]),expression:"[\n          'email',\n          { rules: [{ required: true, message: '请输入' }] }\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"所属角色","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-select',{attrs:{"placeholder":"请选择","mode":"multiple"},on:{"select":_vm.addRole,"deselect":_vm.removeRole}},_vm._l((_vm.roles.get(`USER_ROLE`)),function(role){return _c('a-select-option',{key:role.uuid},[_vm._v(" "+_vm._s(role.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"所属门店","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'facilityIds',
          { rules: [{ required: false, message: '请选择所属门店' }] }
        ]),expression:"[\n          'facilityIds',\n          { rules: [{ required: false, message: '请选择所属门店' }] }\n        ]"}],attrs:{"placeholder":"请选择","mode":"multiple"}},_vm._l((_vm.facilities),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.optionText)+" ")])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }